import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Carousel } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const NewBuilds = () => (
  <Layout>
    <SEO
      title="New Builds"
      description="Building new is your chance to live in the home that suits your family perfectly. You can build with confidence knowing we are a Licensed Building Practitioner."
    />
    <section>
      <div className="container">
        <h1 className="section-heading">New Builds</h1>
        <div className="row">
          <div className="col-lg-6">
            <p>
              Building new is your chance to live in the home that suits your
              family and lifestyle perfectly. After the design and planning is
              done, Devenny Construction can deliver the quality and high
              standard finishes you are dreaming of. We provide the highest
              level of service and strive to meet our clients’ individual needs
              by keeping you well informed through every step of construction.
              From sourcing and managing subcontractors, ordering and scheduling
              material delivery, liaising with architects, engineers and council
              inspectors, the full building process will be taken care of.
            </p>
            <p>
              You can build with confidence knowing we are a Licensed Building
              Practitioner. Our qualifications and experience guarantee your
              investment with peace of mind for years to come.
            </p>
            <p>
              <Link className="font-weight-bold" to="/contact/">
                Get in touch
              </Link>{" "}
              for a free consultation and sound building advice.
            </p>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0 mb-4">
            <Carousel>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "cover" }}
                  src="../images/new-builds/matahiwi/1.webp"
                  alt="Kitchen and dining room"
                  loading="eager"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "cover" }}
                  src="../images/new-builds/matahiwi/2.webp"
                  alt="Main living space"
                  loading="eager"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "cover" }}
                  src="../images/new-builds/matahiwi/3.webp"
                  alt="Dining room"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "cover" }}
                  src="../images/new-builds/matahiwi/4.webp"
                  alt="Bathroom"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "cover" }}
                  src="../images/new-builds/matahiwi/5.webp"
                  alt="Lounge"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "cover" }}
                  src="../images/new-builds/matahiwi/6.webp"
                  alt="Exterior and deck"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "cover" }}
                  src="../images/new-builds/hikunui/1.webp"
                  alt="Kitchen and dining room"
                  loading="eager"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "cover" }}
                  src="../images/new-builds/hikunui/2.webp"
                  alt="Main living space"
                  loading="eager"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "cover" }}
                  src="../images/new-builds/hikunui/3.webp"
                  alt="Lounge"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "cover" }}
                  src="../images/new-builds/hikunui/4.webp"
                  alt="Kitchen and dining room"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "cover" }}
                  src="../images/new-builds/hikunui/5.webp"
                  alt="Deck"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "cover" }}
                  src="../images/new-builds/hikunui/6.webp"
                  alt="Courtyard"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "contain" }}
                  src="../images/new-builds/IMG_3667.jpg"
                  alt="Finished house from outside"
                  loading="eager"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/new-builds/new-build-2.jpg"
                  alt="Lounge and dining room"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/new-builds/new-build-3.jpg"
                  alt="Lounge and dining room"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/new-builds/File_024.jpeg"
                  alt="New deck and windows"
                  loading="eager"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/new-builds/IMG_1656.jpg"
                  alt="Standing house frames"
                  loading="eager"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/new-builds/IMG_1481.jpg"
                  alt="Puring concrete foundation"
                  loading="eager"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NewBuilds
